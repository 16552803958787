import React from "react"
import { useTranslation } from "@3nvi/gatsby-theme-intl"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"

const CompanyPage = ({data}) => {
  const { t } = useTranslation()

  return (
    <Layout>
      <div>
       <GatsbyImage image={data.header.childImageSharp.gatsbyImageData} alt="Company banner"/>
       <section className="both-edges list-disc"
        dangerouslySetInnerHTML={{ __html: data.content.childMarkdownRemark.html }}></section>
        <h2>{t("company.title")}</h2>
       <GatsbyImage image={data.timeline.childImageSharp.gatsbyImageData} alt="Timeline"/>
      </div>
    </Layout>
  )

}

export default CompanyPage

export const pageQuery = graphql`
query($lang: String) {

  timeline: file(sourceInstanceName: {eq: $lang}, name: {eq: "timeline"}) {
    id
      childImageSharp {
        gatsbyImageData 
      }   
  }

  header: file(sourceInstanceName: {eq: $lang}, name: {eq: "company-header"}) {
    id
      childImageSharp {
        gatsbyImageData 
      }   
  }

  content: file(sourceInstanceName: {eq: $lang}, name: {eq: "company"}) {
    id
      childMarkdownRemark {
        html
      }
  }

}
`;
